<template>
  <v-container v-bind="propsPageMainContainer" data-cy="users-create-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("users.createTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-if="authCan('see.users')"
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'users-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
          </div>
          <UsersCreate></UsersCreate>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import UsersCreate from "../components/UsersCreate";

export default {
  name: "UsersCreatePage",
  mixins: [Permissions],
  components: {
    UsersCreate,
  },
};
</script>
