<template>
  <v-sheet v-bind="propsCompSheet" data-cy="users-create">
    <div class="mb-4">
      <v-form :disabled="formDisabled" @submit.prevent>
        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.userName')"
            v-model="editUserName"
            :error-messages="editUserNameErrors"
            @input="$v.editUserName.$touch()"
            @blur="$v.editUserName.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.password')"
            v-model="editPassword"
            :error-messages="editPasswordErrors"
            @input="$v.editPassword.$touch()"
            @blur="$v.editPassword.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.name')"
            v-model="editName"
            :error-messages="editNameErrors"
            @input="$v.editName.$touch()"
            @blur="$v.editName.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.surnames')"
            v-model="editSurnames"
            :error-messages="editSurnamesErrors"
            @input="$v.editSurnames.$touch()"
            @blur="$v.editSurnames.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <UserRolePickerSelect
            :pickerValue="editRole"
            @pickerChanged="editRole = $event"
          >
          </UserRolePickerSelect>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.email')"
            v-model="editEmail"
            :error-messages="editEmailErrors"
            @input="$v.editEmail.$touch()"
            @blur="$v.editEmail.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.phone')"
            v-model="editPhone"
            :error-messages="editPhoneErrors"
            @input="$v.editPhone.$touch()"
            @blur="$v.editPhone.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.profilePicture')"
            v-model="editProfilePicturePath"
            :error-messages="editProfilePicturePathErrors"
            @input="$v.editProfilePicturePath.$touch()"
            @blur="$v.editProfilePicturePath.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-textarea
            v-bind="propsFormFields"
            :label="$t('common.description')"
            v-model="editDescription"
            :error-messages="editDescriptionErrors"
            @input="$v.editDescription.$touch()"
            @blur="$v.editDescription.$touch()"
            type="text"
          >
          </v-textarea>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.address')"
            v-model="editAddress"
            :error-messages="editAddressErrors"
            @input="$v.editAddress.$touch()"
            @blur="$v.editAddress.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.city')"
            v-model="editCity"
            :error-messages="editCityErrors"
            @input="$v.editCity.$touch()"
            @blur="$v.editCity.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.postalCode')"
            v-model="editPostalCode"
            :error-messages="editPostalCodeErrors"
            @input="$v.editPostalCode.$touch()"
            @blur="$v.editPostalCode.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <CountryPickerSelect
            :pickerValue="editCountryIso3166_a2"
            @pickerChanged="editCountryIso3166_a2 = $event"
          >
          </CountryPickerSelect>
        </div>

        <div class="formField">
          <LanguagePickerSelect
            :pickerValue="editLanguageIso639_1"
            @pickerChanged="editLanguageIso639_1 = $event"
          >
          </LanguagePickerSelect>
        </div>
      </v-form>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <v-btn
        v-if="authCan('create.users')"
        v-bind="propsButtonAction"
        :loading="status.loading"
        :disabled="!formReadyForSubmit"
        @click="clickCreate"
        data-cy="button-create"
        >{{ $t("common.create") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import UserRolePickerSelect from "@/modules/users/bits/UserRolePickerSelect";
import CountryPickerSelect from "@/modules/base/bits/CountryPickerSelect";
import LanguagePickerSelect from "@/modules/base/bits/LanguagePickerSelect";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "UsersCreate",
  mixins: [Permissions, ComponentStatus],
  components: {
    UserRolePickerSelect,
    CountryPickerSelect,
    LanguagePickerSelect,
  },
  data: () => ({
    firstFormClick: true,
    // New proxy form data
    editUserName: "",
    editPassword: "",
    editName: "",
    editSurnames: "",
    editRole: -1,
    editEmail: "",
    editPhone: "",
    editProfilePicturePath: "",
    editDescription: "",
    editAddress: "",
    editCity: "",
    editPostalCode: "",
    editCountryIso3166_a2: "",
    editLanguageIso639_1: "",
  }),
  validations: {
    editUserName: {
      required,
      maxLength: maxLength(100),
    },
    editPassword: {
      required,
      maxLength: maxLength(100),
    },
    editName: {
      required,
      maxLength: maxLength(100),
    },
    editSurnames: {
      maxLength: maxLength(100),
    },
    editRole: {
      required,
      maxLength: maxLength(100),
    },
    editEmail: {
      required,
      maxLength: maxLength(100),
    },
    editPhone: {
      maxLength: maxLength(100),
    },
    editProfilePicturePath: {
      maxLength: maxLength(100),
    },
    editDescription: {
      maxLength: maxLength(1000),
    },
    editAddress: {
      maxLength: maxLength(100),
    },
    editCity: {
      maxLength: maxLength(100),
    },
    editPostalCode: {
      maxLength: maxLength(100),
    },
    editCountryIso3166_a2: {
      maxLength: maxLength(100),
    },
    editLanguageIso639_1: {
      maxLength: maxLength(100),
    },
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    editUserNameErrors() {
      const errors = [];
      if (!this.$v.editUserName.$dirty) return errors;
      if (!this.$v.editUserName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editUserName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPasswordErrors() {
      const errors = [];
      if (!this.$v.editPassword.$dirty) return errors;
      if (!this.$v.editPassword.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editPassword.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editSurnamesErrors() {
      const errors = [];
      if (!this.$v.editSurnames.$dirty) return errors;
      if (!this.$v.editSurnames.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editRoleErrors() {
      const errors = [];
      if (!this.$v.editRole.$dirty) return errors;
      if (!this.$v.editRole.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editRole.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editEmailErrors() {
      const errors = [];
      if (!this.$v.editEmail.$dirty) return errors;
      if (!this.$v.editEmail.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editEmail.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPhoneErrors() {
      const errors = [];
      if (!this.$v.editPhone.$dirty) return errors;
      if (!this.$v.editPhone.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editProfilePicturePathErrors() {
      const errors = [];
      if (!this.$v.editProfilePicturePath.$dirty) return errors;
      if (!this.$v.editProfilePicturePath.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      if (!this.$v.editDescription.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editAddressErrors() {
      const errors = [];
      if (!this.$v.editAddress.$dirty) return errors;
      if (!this.$v.editAddress.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editCityErrors() {
      const errors = [];
      if (!this.$v.editCity.$dirty) return errors;
      if (!this.$v.editCity.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPostalCodeErrors() {
      const errors = [];
      if (!this.$v.editPostalCode.$dirty) return errors;
      if (!this.$v.editPostalCode.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editCountryIso3166_a2Errors() {
      const errors = [];
      if (!this.$v.editCountryIso3166_a2.$dirty) return errors;
      if (!this.$v.editCountryIso3166_a2.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editLanguageIso639_1Errors() {
      const errors = [];
      if (!this.$v.editLanguageIso639_1.$dirty) return errors;
      if (!this.$v.editLanguageIso639_1.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
  },
  methods: {
    clickCreate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        this.sendCreate();
      }
    },
    sendCreate() {
      this.setStatusCreating();
      let payload = {
        userName: this.editUserName,
        password: this.editPassword,
        passwordConfirmation: this.editPassword,
        name: this.editName,
        surnames: this.editSurnames,
        role: this.editRole,
        email: this.editEmail,
        phone: this.editPhone,
        profilePicturePath: this.editProfilePicturePath,
        description: this.editDescription,
        address: this.editAddress,
        city: this.editCity,
        postalCode: this.editPostalCode,
        countryIso3166_a2: this.editCountryIso3166_a2,
        languageIso639_1: this.editLanguageIso639_1,
        organisationId: this.editOrganisationId,
      };
      this.$store
        .dispatch("users/create", payload)
        .then((result) => {
          // Removing all errors from server.
          this.$v.$touch();
          // Setting component status to success.
          this.setStatusCreateSuccess();
          this.$store.commit("status/showSuccess");
          if (result) {
            this.$router.push({
              name: "users-show",
              params: {
                id: result,
              },
            });
          } else {
            this.$router.push({
              name: "users-index",
            });
          }
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          // Setting component status to failure.
          this.setStatusCreateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
